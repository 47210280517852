import { FC, useState, useEffect } from 'react'

import { Icon, Flex, Box, SystemProps, Stack } from '@storyofams/react-ui'
import { endsWith, startsWith } from 'lodash'
import { useRouter } from 'next/router'
import { defineMessages, useIntl } from 'react-intl'
import { CartButton, CartOverlay, Button, Divider } from '~components'
import { useShopify } from '~context'

import { Progress } from '~components/orders'
import {
  Cross,
  NewCart as CartIcon,
  NewLogo,
  NewLogoMobile,
  Hamburger,
  User,
  ArrowLeft,
} from '../../Icons'
import { NavigationLink } from './NavigationLink'

const messages = defineMessages({
  myAccount: 'My account',
  cart: 'Cart',
  checkoutStep1Label: 'Select a box',
  checkoutStep2Label: 'Select your meals',
  checkoutStep3Label: 'Order overview',
  backToMeals: 'Back to meals',
})

interface NavigationPrimaryProps extends SystemProps {
  hasSearchButton?: boolean
  mobileMenuOpen: boolean
  setMobileMenuOpen(boolean): void
}

export const NavigationPrimary: FC<NavigationPrimaryProps> = ({
  children,
  hasSearchButton,
  mobileMenuOpen,
  setMobileMenuOpen,
  ...props
}) => {
  const router = useRouter()
  const intl = useIntl()
  const { showCartOverlay, toggleCartOverlay, portion, quantity } = useShopify()
  const locale = (router?.locale || process.env.DEFAULT_LOCALE)?.toUpperCase()
  const [accountLink, setAccountLink] = useState('')

  useEffect(() => {
    switch (locale) {
      case 'NL':
        setAccountLink(process.env.NEXT_PUBLIC_SELF_SERVICE_NL)
        break
      case 'EN':
        setAccountLink(process.env.NEXT_PUBLIC_SELF_SERVICE_EN)
        break
    }
  }, [locale])

  const checkoutSteps = [
    // {
    //   label: intl.formatMessage(messages.checkoutStep1Label),
    //   route: '/order/select-box',
    //   completed: !!portion,
    // },
    {
      label: intl.formatMessage(messages.checkoutStep2Label),
      route: '/meals',
      completed: quantity >= 6,
    },
    {
      label: intl.formatMessage(messages.checkoutStep3Label),
      route: '/order/overview',
    },
  ].map(item => ({
    ...item,
    current: endsWith(router?.pathname, item.route),
  }))

  const isCheckoutFlow = startsWith(router?.pathname, '/order')

  const isProductPage = startsWith(router?.pathname, '/meals/')

  // const buttonProps = isCheckoutFlow || isProductPage
  //   ? router?.query?.handle
  //     ? { to: '/meals?ordering=true' }
  //     //: startsWith(router.pathname, '/order/select-box')
  //     : startsWith(router.pathname, '/meals')
  //       ? { to: '/' }
  //       : checkoutSteps.findIndex((step) => step.current) > 0
  //         ? {
  //             to: `${
  //               checkoutSteps[checkoutSteps.findIndex((step) => step.current) - 1]
  //                 ?.route
  //             }?ordering=true`,
  //           }
  //         : { onClick: () => router.back() }
  //   : { onClick: () => setMobileMenuOpen(true) };

  const buttonProps = { onClick: () => setMobileMenuOpen(true) }
  return (
    <>
      <Flex
        as="nav"
        alignItems="center"
        justifyContent={['space-between', 'space-between', 'initial']}
        height={['52px', '52px', '80px']}
        maxWidth="maxWidth"
        mx="auto"
        px={[3, 3, 6]}
        {...props}
      >
        {/* {!(isCheckoutFlow && router?.query?.handle) && ( */}
        {/* {(!(router?.query?.handle) || isProductPage) && ( */}
        <Button
          display={['flex']}
          variant="unstyled"
          to="/"
          mr={['auto', 'auto', 0]}
        >
          <Box display={['none', 'none', 'block']}>
            <Icon icon={NewLogo} fontSize={['91px']} />
          </Box>
          <Box display={['block', 'block', 'none']}>
            <Icon icon={NewLogo} fontSize={['60px']} />
          </Box>
        </Button>
        {/* )} */}

        {/* {isCheckoutFlow && !router?.query?.handle && (
          <Progress steps={checkoutSteps} />
        )} */}

        <Box
          position={['fixed', 'fixed', 'static']}
          display={['flex !important', 'flex !important', 'none !important']}
          top={[0, 0, 'unset']}
          left={[
            mobileMenuOpen ? 0 : '-100%',
            mobileMenuOpen ? 0 : '-100%',
            'unset',
          ]}
          bottom={[0, 0, 'unset']}
          width="100%"
          backgroundColor={'rgba(45,45,45,.65)' as any}
          zIndex={'overlay' as any}
          transition="opacity .24s ease-out"
          opacity={[mobileMenuOpen ? 1 : 0, mobileMenuOpen ? 1 : 0, 0]}
          onClick={() => setMobileMenuOpen(false)}
        />

        <Box
          position={['fixed', 'fixed', 'static']}
          top={[0, 0, 'unset']}
          left={[
            mobileMenuOpen ? 0 : '-100%',
            mobileMenuOpen ? 0 : '-100%',
            'unset',
          ]}
          bottom={[0, 0, 'unset']}
          flex={1}
          backgroundColor="#21857F"
          zIndex={'overlay' as any}
          transition="left .24s ease-out"
          maxWidth={['360px', '360px', 'none']}
          height="100%"
          width="100%"
          px={[4, 4, 4]}
          py={2}
        >
          <Flex
            justifyContent="space-between"
            display={['flex !important', 'flex !important', 'none !important']}
          >
            <Button variant="unstyled" to="/">
              <Icon icon={NewLogo} fontSize={['42px', '91px']} />
            </Button>

            <Button
              variant="unstyled"
              alignItems="center"
              justifyContent="center"
              onClick={() => setMobileMenuOpen(false)}
              height={56}
              width={56}
              mr="-21px"
            >
              <Icon fontSize={3} icon={Cross} />
            </Button>
          </Flex>

          <Divider mt={8} mb={4} display={{ md: 'none' }} />
          {/* {!isCheckoutFlow && children} */}
          {children}

          <Box width="100%" display={{ md: 'none' }}>
            <Divider mt={4} mb="36px" />
            <Stack space={5} flexDirection="column">
              <NavigationLink link={`${accountLink}`} alignItems="center">
                <Icon icon={User} fontSize={2} color="inherit" mr={2} />
                {intl.formatMessage(messages.myAccount)}
              </NavigationLink>

              {!!quantity && (
                <NavigationLink link="/order/overview" alignItems="center">
                  <Icon icon={CartIcon} fontSize={2} color="inherit" mr={2} />
                  {intl.formatMessage(messages.cart)}
                </NavigationLink>
              )}
            </Stack>
          </Box>
        </Box>

        <Flex
          alignItems="center"
          position="relative"
          gap={3}
          flexDirection={['row-reverse', 'row-reverse', 'row']}
        >
          {/* {!isCheckoutFlow && ( */}
          <Button
            variant="unstyled"
            alignItems="center"
            width={'30px'}
            display={['flex', 'flex', 'none !important']}
            {...buttonProps}
          >
            <Icon
              icon={Hamburger}
              fontSize={3}
              color="#FAF7F5"
              display={{ md: 'none !important' }}
            />
          </Button>
          <CartButton
            quantity={quantity}
            cartOpen={showCartOverlay}
            // toggleCartOverlay={toggleCartOverlay}
            // setCartOpen={() => toggleCartOverlay(true)}
          />
        </Flex>
      </Flex>

      <CartOverlay isOpen={showCartOverlay} close={toggleCartOverlay} />
    </>
  )
}
